<template>
    <div class="home_marriage_test_payment" id="agentTitle">
        <header>
            <div class="banner_img_box_"><img src="http://cdn.zhiyileiju.cn/WechatIMG1338.jpeg" alt=""></div>
            <!-- <h2>能找到真正爱你的人吗? <br> 最终会有好结果吗？</h2>
            <div class="box">
                <div class="top_box__">
                    <div class="center_box">
                        <div class="bottom_box">
                            <p>亲爱的<span>{{name}}：</span></p>
                            <p>从测算结果来看，<span>2022年底</span>你的姻缘将会出现特别重要的<span>婚姻感情转机</span>！</p>
                        </div>
                    </div>
                </div>
            </div> -->
        </header>
        <section>
            <div class="top_box__">
                <div class="card_box" style="">
                    <div class="title_user_info_img_box_">
                        <div class="title_user_info_img_text_">{{name}}虎年运势详批</div>
                        <img src="http://cdn.zhiyileiju.cn/WechatIMG1339.png" alt="">
                    </div>
                   <div class="current_price_box_">
                       <span class="current_price_left_">限时优惠￥{{price.lucky_amount}}元 </span><span class="current_price_right_">距优惠结束还剩</span>
                   </div>
                   <div class="original_price_box_">
                       <span class="original_price_left_">原价￥{{price.fake_amount}}</span>
                       <span class="original_price_right_">
                            <van-count-down  @change="timeTrigger" millisecond :time="time">
                                <template #default="timeData">
                                    <span class="block_">0{{ timeData.hours }}</span>
                                    <span class="colon">:</span>
                                    <span class="block_">0{{ timeData.minutes }}</span>
                                    <span class="colon">:</span>
                                    <span class="block_">{{ timeData.seconds &lt; 10 ? '0'+timeData.seconds : timeData.seconds }}</span>
                                    <span class="colon">:</span>
                                    <span class="block_">{{ parseInt(timeData.milliseconds / 10) }}</span>
                                </template>	
                            </van-count-down>
                       </span>
                   </div>
                   <div class="payment_box_" @click="selectButtom('WeChat')">
                        <i class="icon_img_box_"><img src="http://cdn.zhiyileiju.cn/WechatIMG1268.png" alt=""></i>
                        <span class="span_texta_">微信支付</span>
                        <span :class="wechat_payment_style"><i class="i_circle_"></i></span>
                   </div>
                   <div class="payment_box_ alipay_box_" @click="selectButtom('alipay')" v-if="!this.is_weixn">
                        <i class="icon_img_box_"><img src="http://cdn.zhiyileiju.cn/WechatIMG1269.png" alt=""></i>
                        <span class="span_texta_">支付宝支付</span>
                        <span :class="alipay_payment_style"><i class="i_circle_"></i></span>
                   </div>
                   <div class="submit_buttom_box_ animation_scaling" @click="paymentButtom(payment_types_)">
                       点击领取运势报告
                   </div>
                   <div class="text_info_" style="padding-left:1.4rem;"><i class="icon_head_portrait"><img src="http://cdn.zhiyileiju.cn/WechatIMG1336.jpeg" alt=""></i> 已有 <span class="span_color_">8,998,716</span> 人领取了虎年运势报告</div>
                   <div class="text_info_"><span class="span_color_">99.7%</span>的用户认为对他们的分析结果很满意</div>
                   <div class="img_brand_box_"><img src="http://cdn.zhiyileiju.cn/WechatIMG1289.png" alt=""></div>
                   <div class="bottom_text_">支付系统已通过安全联盟认证，答案仅自己可见</div>
                </div>
                <div class="title___">支付完成后你将获得以下重要内容</div>
                <div class="card_box" style="padding-bottom:0;">
                    <div class="user_info_text_box_" style="margin-top:.6rem;">姓<span>名</span>：{{name}}</div>
                    <div class="user_info_text_box_">性<span>别</span>：{{userinfo.sex}}</div>
                    <div class="user_info_text_box_">出生日期：</div>
                    <div class="user_info_text_box_">公<span>历</span>：{{userinfo.time_of_birth}}</div>
                    <!-- <div class="user_info_text_box_">阴<span>历</span>：(丙子年)一九九六年 二月 廿七 卯时</div> -->
                    <div class="user_info_text_box_" style="margin-bottom:.6rem">阴<span>历</span>：{{`(${lunar_calendar_value.gzYear}年)${lunar_calendar_value.yearCn}${lunar_calendar_value.monthCn}${lunar_calendar_value.dayCn}`}}</div>
                </div>
                <div class="card_box">
                    <h1 class="h1_title_text__">2022虎年整体运势</h1>
                    <div class="dim_img_box_"><img src="http://cdn.zhiyileiju.cn/WechatIMG1291.png" alt=""></div>
                    <div class="blank_box_" style="padding-top:.3rem">
                        <div class="text___">1、分析你2022虎年感情、财运、事业、 职场等整体运势会迎来哪些变化。</div>
                        <div class="text___" style="padding-bottom:.5rem">2、分析你2022虎年每月生肖综合运势</div>
                        <!-- <div class="text___"></div> -->
                    </div>
                    <div class="unlock_buttom_img_box_" @click="paymentButtom(payment_types_,'unlock')" ><img src="http://cdn.zhiyileiju.cn/WechatIMG1295.png" alt=""></div>
                </div>
                <div class="card_box">
                    <h1 class="h1_title_text__">2022虎年感情运势</h1>
                    <div class="dim_img_box_"><img src="http://cdn.zhiyileiju.cn/WechatIMG1290.png" alt=""></div>
                    <div class="blank_box_" style="padding-top:.3rem">
                        <div class="text___" >1、分析你2022虎年感情稳定情况</div>
                        <div class="text___" style="padding-bottom:.5rem">2、分析你2022虎年命中桃花运如何</div>
                        <!-- <div class="text___"></div> -->
                    </div>
                    <div class="unlock_buttom_img_box_" @click="paymentButtom(payment_types_)"  style="top: 4.26rem;"><img src="http://cdn.zhiyileiju.cn/WechatIMG1295.png" alt=""></div>
                </div>
                <div class="card_box">
                    <h1 class="h1_title_text__">2022虎年财运运势</h1>
                    <div class="dim_img_box_"><img src="http://cdn.zhiyileiju.cn/WechatIMG1291.png" alt=""></div>
                    <div class="blank_box_" style="padding-top:0;">
                        <div class="text___">1、分析你2022虎年先天财运格局详情</div>
                        <div class="text___">2、分析你2022虎年财富机遇关键点在 哪里</div>
                        <div class="text___">3、分析你2022虎年每月生肖综合运势</div>
                    </div>
                    <div class="unlock_buttom_img_box_" @click="paymentButtom(payment_types_)"  style="top: 4.75rem;"><img src="http://cdn.zhiyileiju.cn/WechatIMG1295.png" alt=""></div>
                </div>
                <div class="card_box">
                    <h1 class="h1_title_text__">2022虎年事业运势</h1>
                    <div class="dim_img_box_"><img src="http://cdn.zhiyileiju.cn/WechatIMG1291.png" alt=""></div>
                    <div class="blank_box_" style="padding-top:0;">
                        <div class="text___">1、分析你2022虎年整体事业运势如何</div>
                        <div class="text___">2、分析你2022虎年事业格局详情</div>
                        <div class="text___">3、分析你2022虎年升职加薪概率</div>
                        <div class="text___">4、分析你2022虎年在事业上应当注意 哪些地方</div>
                    </div>
                    <div class="unlock_buttom_img_box_" @click="paymentButtom(payment_types_)"  style="top: 5.23rem;"><img src="http://cdn.zhiyileiju.cn/WechatIMG1295.png" alt=""></div>
                </div>
                <div class="card_box">
                    <h1 class="h1_title_text__">2022虎年流月运势</h1>
                    <div class="dim_img_box_"><img src="http://cdn.zhiyileiju.cn/WechatIMG1291.png" alt=""></div>
                    <div class="blank_box_" style="padding-top:0;">
                        <div class="text___">1、分析你2022虎年每月的运势好坏</div>
                        <div class="text___">2、分析你2022虎年感情、事业、财运 等方面变化</div>
                        <div class="text___">3、分析你2022虎年具体注意事项</div>
                    </div>
                    <div class="unlock_buttom_img_box_" @click="paymentButtom(payment_types_)"  style="top: 4.75rem;"><img src="http://cdn.zhiyileiju.cn/WechatIMG1295.png" alt=""></div>
                </div>
                <div class="card_box">
                    <h1 class="h1_title_text__">2022虎年化解太岁锦囊</h1>
                    <div class="dim_img_box_"><img src="http://cdn.zhiyileiju.cn/WechatIMG1290.png" alt=""></div>
                    <div class="blank_box_" style="padding-top:0;">
                        <div class="text___">1、2022虎年化解太岁方法</div>
                        <div class="text___"> ......</div>
                        <div class="text___"> </div>
                    </div>
                    <div class="unlock_buttom_img_box_" @click="paymentButtom(payment_types_)"  style="top: 4.26rem;"><img src="http://cdn.zhiyileiju.cn/WechatIMG1295.png" alt=""></div>
                </div>
                <div class="card_box">
                    <h1 class="h1_title_text__">2022虎年增运建议</h1>
                    <div class="dim_img_box_"><img src="http://cdn.zhiyileiju.cn/WechatIMG1291.png" alt=""></div>
                    <div class="blank_box_" style="padding-top:0;">
                        <div class="text___">1、2022虎年运程把握建议</div>
                        <div class="text___"> 2、2022虎年流年不利提醒</div>
                        <div class="text___"> 3、2022虎年老师温馨寄语</div>
                        <div class="text___"> </div>
                    </div>
                    <div class="unlock_buttom_img_box_" @click="paymentButtom(payment_types_)" style="top: 4.75rem;"><img src="http://cdn.zhiyileiju.cn/WechatIMG1295.png" alt=""></div>
                </div>



                <!-- <div class="card_box">
                    <p class="top_p__">性别：{{userinfo.sex}} <span>公历：{{userinfo.time_of_birth}}</span></p>
                    <div class="card_content_box">
                        <h2> <span>限时优惠￥{{price.marriage_amount}}元</span>  <i>距优惠结束</i> </h2>
                        <p style="border-bottom:1px solid rgba(202, 166, 146,0.5);padding-bottom:.28rem"><i>原价￥{{price.fake_amount}}元</i> <span><van-count-down @change="timeTrigger" millisecond :time="time" format="HH:mm:ss:SS" style="color:#C91C18"/></span></p>
                        <h3><i>含一生婚姻情况，脱单或复合机会，真爱长相</i></h3>
                        <div class="buttom_box" style="background: #04C967;" @click="paymentButtom('wechat')">
                            <img src="http://cdn.zhiyileiju.cn/WechatIMG381.jpeg" alt="">
                        </div>
                        <div class="buttom_box" style="background: #1B95D4;margin-top:.3rem;" @click="paymentButtom('alipay')" v-if="!this.is_weixn">
                             <img src="http://cdn.zhiyileiju.cn/WechatIMG380.jpeg" alt="">
                        </div>
                        <p class="bottom_p"><i>已有<strong>9, 789, 316</strong> 人查看结果， <strong>99.7%</strong>觉得对婚姻有帮助</i></p>
                        <div class="icon_brand">
                            <img src="http://cdn.zhiyileiju.cn/baozhang.png" alt="">
                        </div>
                        <p class="bottom_p_icon_brand">支付系统已通过安全联盟认证，答案仅自己可见</p>
                    </div>
                </div>
                 -->
               
             
               
                
               
                
                <!-- <div class="card_box">
                    <div class="card_top_box">
                        <h1>好评如潮</h1>
                    </div>
                    <img src="http://cdn.zhiyileiju.cn/title_left_flower.jpg" alt="" class="img_icom_left">
                    <img src="http://cdn.zhiyileiju.cn/title_right_flower.jpg" alt="" class="img_icom_right">
                    <Comments />
                    <p style="text-align:center;margin-bottom: .3rem;">最新支付订单</p>
                    <orderscroll :prices='price'> </orderscroll>
                </div> -->
            </div>
            <!-- <h4 class="bottom_title">资深专家团队倾力打造</h4> -->
        </section>
        <footer>
            <!-- <div class="top_brand">
                <div class="img_box" style="width: 1.56rem"><img src="http://cdn.zhiyileiju.cn/home_marriage_test_brand_3.jpg" alt=""></div>
                <div class="img_box" style="width: 1.7rem"><img src="http://cdn.zhiyileiju.cn/home_marriage_test_brand_2.jpg" alt=""></div>
                <div class="img_box" style="width: 2.51rem"><img src="http://cdn.zhiyileiju.cn/home_marriage_test_brand_1.jpg" alt=""></div>
            </div> -->
            <div class="buttom_anchor" v-if="fixedbtn" @click="backToTheTopCallback('anchor')">
                立即查看运势报告
            </div>
        </footer>
        <van-overlay :show="shopPayConfirm" z-index="10" lock-scroll>
            <div class="wrapper center" @click.stop>
                <div class="block">
                    <div class="title">距运势解析只差1步<br></div>
                    <div class="title title_tow">支付后查看虎年运势详批</div>
                    <div class="bg_box_chunk_">
                        <span class="only_remain_text">仅剩{{percentage_num}}%</span>
                        <div class="img_bg_box"></div>
                        <div class="bottom_text_box__">
                            <span class="bottom_text__">信息提交</span>
                            <span class="bottom_text__">生成报告</span>
                            <span class="bottom_text__">查看运势详批</span>
                        </div>
                    </div>
                    <div class="payed payment_" @click="paymentButtoms('')"><img src="http://cdn.zhiyileiju.cn/WechatIMG600.jpeg" alt=""></div>
                    <div class="payed unpay_" @click="unpay">{{popup_text}}</div>
                    <div class="payed  Dcheckorder_" @click="Dcheckorder">已完成支付</div>
                    <div class="count_down_box_">
                         <van-count-down :time="counttime" millisecond>
                            <template #default="timeData">
                                <span class="block_">{{ timeData.hours }}</span>
                                <span class="colon">:</span>
                                <span class="block_">{{ timeData.minutes }}</span>
                                <span class="colon">:</span>
                                <span class="block_">{{ timeData.seconds }}</span>
                                <span class="colon">:</span>
                                <span class="block_">{{ parseInt(timeData.milliseconds / 10) }}</span>
                                <span class="colon text">后失效</span>
                            </template>	
                        </van-count-down>
                    </div>
                </div>
            </div>
        </van-overlay>
        <!--  投诉 -->
        <span  class="fixation_complaint" v-if="complaint_buttom_fal" @click="complaintButtom">
            <img src="https://cdn.zhiyileiju.cn/WechatIMG559.pic" alt="">
        </span>
    </div>
</template>

<script>
import Comments from "../../components/Comment.vue";
import orderscroll from "../../components/OrderScroll.vue";
import payment from "../../mixin/fleetingTimeFortune/payment"

export default {
     mixins:[payment],
   components: {
       Comments,orderscroll
   },
   
    
}
</script>

<style lang="scss" scoped>
.home_marriage_test_payment{
    background: #fffae8;
    // position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    header{
        .banner_img_box_{
            width: 7.5rem;
        }
    }
    section{
        .top_box__{
            width: 100%;
            margin-top: .36rem;
           
            .card_box{
                position: relative;
                width: 7.05rem;
                background: #fff;;
                border:.01rem solid #E7010E;
                border-radius: .1rem;
                margin: 0 auto;
                padding-bottom: .5rem;
                margin-bottom: .4rem;
                .title_user_info_img_box_{
                    position:absolute;
                    top: -0.64rem;
                    left: 50%;
                    transform: translate(-50%);
                    width: 4.59rem;
                    .title_user_info_img_text_{
                        position: absolute;
                        text-align: center;
                        width: 100%;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%,-50%);
                        font-size: .38rem;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #E7010E;
                    }
                }
                .current_price_box_{ // 时限优惠
                    display: flex;
                    justify-content: space-between;
                    padding-left: .31rem;
                    padding-right: .27rem;
                    margin-top: .48rem;
                    span{
                        height:.34rem;
                        line-height: .34rem;
                        font-size: .34rem;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #E7010E;
                    }
                    .current_price_left_{
                        font-size: .34rem;
                        color: #E7010E;
                    }
                    .current_price_right_{
                        font-size: .26rem;
                        font-weight: 400;
                        color: #323232;
                    }
                }
                .original_price_box_{//原价
                    display: flex;
                    justify-content: space-between;
                    padding-left: .31rem;
                    padding-right: .27rem;
                    margin-top: .13rem;
                    span{
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                    }
                    .original_price_left_{
                        height: .43rem;
                        line-height: .43rem;
                        font-size: .24rem;
                        color: #979797;
                        text-decoration: line-through
                    }
                    .original_price_right_{
                        .colon {
                            display: inline-block;
                            margin: 0 .04rem;
                            font-size: .2rem;
                            color: #E7010E;
                        }
                        .block_ {
                            display: inline-block;
                            width: .43rem;
                            color: #fff;
                            font-size: .24rem;
                            text-align: center;
                            background-color: #E7010E;
                            box-sizing:border-box;
                            border: 1px solid #E7010E;
                        }
                    }
                }
                .payment_box_{ // 微信支付宝选项框
                    position: relative;
                    width: 6.54rem;
                    height: .76rem;
                    box-sizing: border-box;
                    border: .01rem solid rgba(0,0,0,.2);
                    border-left: none;
                    border-right: none;
                    margin: auto;
                    margin-top: .4rem;
                    .icon_img_box_{
                        position: absolute;
                        left: .42rem;
                        top: .24rem;
                        width: .32rem;
                        img{
                            position: absolute;
                        }
                    }
                    .span_texta_{
                        position: absolute;
                        left: .99rem;
                        height: .76rem;
                        font-size: .28rem;
                        font-weight: 400;
                        color: #525252;
                        line-height: .76rem;
                    }
                    .payment_pitch_on_{//选中支付方式样式
                        position: absolute;
                        right: .52rem;
                        top: 50%;
                        transform: translate(0,-50%);
                        width: .33rem;
                        height: .33rem;
                        border: .01px solid #02A9F1;
                        border-radius: 50%;
                        .i_circle_{
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%,-50%);
                            width: .15rem;
                            height: .15rem;
                            background: #02A9F1;
                            border-radius: 50%;
                        }
                    }
                    .payment_pitch_unselected_{//未选中支付方式样式
                        position: absolute;
                        right: .52rem;
                        top: 50%;
                        transform: translate(0,-50%);
                        width: .33rem;
                        height: .33rem;
                        border: .01px solid #929292;
                        border-radius: 50%;
                    }
                }
                .alipay_box_{ // 支付宝选项框
                    margin-top: 0;
                    border-top: none;
                }
                .submit_buttom_box_{ 
                    width: 5.69rem;
                    height: .92rem;
                    background: #E7010E;
                    box-shadow: 0px .03px .07px 0px rgba(0, 0, 0, 0.36);
                    border-radius: .46rem;
                    margin: auto;
                    margin-top: .23rem;
                    font-size: .36rem;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #ffff;
                    text-align: center;
                    line-height: .92rem;
                    margin-bottom: .27rem;
                }
                .text_info_{
                    position: relative;
                    margin-top: .27rem;
                    text-align: center;
                    font-size: .24rem;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #525252;
                    .icon_head_portrait{
                        position: absolute;
                        left: .92rem;
                        width: 1.14rem;
                    }
                    .span_color_{
                        color: #E7010E;
                    }
                }
                .img_brand_box_{ //支付商标
                    width: 6.41rem;
                    margin: auto;
                }
                .bottom_text_{
                    text-align: center;
                    font-size: .24rem;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #19AA0F;
                }
                .img_title_box__{
                    position: absolute;
                    top: -.62rem;
                    left: 50%;
                    transform: translate(-50%);
                    width: 4.61rem;
                }
                .user_info_text_box_{
                    font-size: .28rem;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #525252;
                    margin-bottom: .27rem;
                    margin-left: .6rem;
                    span{
                        display: inline-block;
                        text-indent: 2em;
                    }
                }
                .gif_img_box_{
                    width: 4.78rem;
                    margin: auto;
                    margin-top: .43rem;
                }
                .h1_title_text__{
                    width: 100%;
                    text-align: center;
                    position: absolute;
                    top: .4rem;
                    left: 50%;
                    transform: translate(-50%);
                    font-size: .38rem;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #E7010E;
                }
                .blank_box_{
                    width: 5.65rem;
                    position: absolute;
                    top: 1.68rem;
                    left: 50%;
                    transform: translate(-50%);
                    padding-top: .5rem;
                    padding-bottom: .27rem;
                    background: #fff;
                    .text___{
                        font-size: .28rem;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #323232;
                        margin-left: .27rem;
                        margin-right: .27rem;
                        margin-top: .27rem;
                    }
                }
                .unlock_buttom_img_box_{
                    width: 5.69rem;
                    position: absolute;
                    top: 4.75rem;  
                    left: 50%;
                    transform: translate(-50%);
                }



                .card_top_box{
                    position: relative;
                    top: -0.54rem;
                    width: 5.54rem;
                    height: 1.08rem;
                    margin: 0 auto;
                    background: url("http://cdn.zhiyileiju.cn/title_bg.jpg ");
                    background-size: 100% 100%;
                    // margin-bottom:1rem;
                    h1{
                        font-size: .36rem;
                        text-align: center;
                        line-height: 1.08rem;
                        color: #fecc6d;
                    }
                }
                .img_icom_left{
                    width: .6rem;
                    position: absolute;
                    top: -.18rem;
                    left: -0.05rem;
                }
                .img_icom_right{
                    width: .6rem;
                    position: absolute;
                    top: -.18rem;
                    right: -0.05rem;
                }
                p{
                    font-size: .28rem;
                    color: #A90017;
                    // text-align: center;
                    span{
                        margin-left: .28rem;
                    }
                    // border-bottom: 1px solid #FCAF93;
                }
                .top_p__{
                    background: #F8CAA6;
                    height: .87rem;
                    line-height: .87rem;
                    margin: 0 .32rem;
                    border-radius: .1rem;
                    text-indent: 1em;
                }
                .card_content_box{
                    margin-top: .34rem;
                    padding:0 .32rem;
                    i{
                        font-size: .26rem;
                        color: #C98578;
                    }
                    h2{
                        position: relative;
                        height: .63rem;
                        font-weight: 500;
                        font-size: .36rem;
                        color: #A80009;
                        span{
                            display: inline-block;
                            height: .63rem;
                            background: #C91C18;
                            border-radius: .1rem;
                            padding: 0 .23rem;
                            line-height: .63rem;
                            color: #FFFFFF;
                        }
                        i{
                            position: absolute;
                            right: 0;
                            bottom: 0;
                        }
                    }
                    p{
                        position: relative;
                        border-bottom: none;
                        padding-bottom: 0;
                        text-align: left;
                        i{
                            text-decoration:line-through ;
                        }
                        span{
                            position: absolute;
                            right: 0;
                        }
                    }
                    h3{
                        margin-top:.3rem;
                        font-weight: 400;
                        text-align: center;
                        i{
                            font-size: .28rem;
                        }
                    }
                    .buttom_box{
                        width: 6.41rem;
                        // height: .95rem;
                        border-radius: .95rem;
                        cursor:pointer;
                        margin-top:.47rem;
                        p{
                            position: relative;
                            text-align: center;
                            line-height: .95rem;
                            font-size: .36rem;
                            color: #FDFDFD;
                            padding-left: 1rem;
                            img{
                                position: absolute;
                                width: .70rem;
                                top: .12rem;
                                left: 1.8rem;       
                            }
                        }
                    }
                    .bottom_p{
                        text-align: center;
                        margin-top: .28rem;
                        i{
                            text-decoration:none ;
                            strong{
                                color: #a90017;
                                font-weight: 600;
                            }
                        }
                    }
                    .bottom_p_icon_brand{
                        text-align: center;
                        font-size: .24rem;
                        color: #19AA0F;
                    }
                }
                .text_dim_box{
                    margin-top: .16rem;
                    p{
                        padding: 0 .2rem;
                        color: transparent;
                        text-shadow: 2px 2px 3px #000;
                        font-size: .24rem;
                        text-align: left;
                        border-bottom: none;
                        line-height:.4rem;
                        letter-spacing:1px;
                    }
                }
                .suspend_text_box{
                    position: absolute;
                    top: 3rem;
                    left: 50%;
                    background: #fff;
                    transform: translate(-50%,-50%);
                    width: 5.65rem;
                    // height: 2.66rem;
                    padding-bottom: .5rem;
                    border-radius: .1rem;
                    p{
                        text-align: left;
                        border-bottom: none;
                        font-size: .28rem;
                        color: #323232;
                        margin-left: .5rem;
                        line-height: .6rem;
                    }
                }
                .buttom_box{
                    // position: absolute;
                    // top: 4.85rem;;
                    // left: 0;
                    // right: 0;
                    width: 5.69rem;
                    height: .91rem;
                    margin: auto;
                    border-radius: .91rem;
                    p{
                        position: relative;
                        text-align: center;
                        border:none;
                        line-height: .91rem;
                        color: #FFF0D3;
                        font-size: .36rem;
                        img{
                            position: absolute;
                            top: 30%;
                            left: 22%;
                            width: .36rem;
                            // margin-top: .3rem;
                        }
                        
                    }
                }
                .buttom_box_text__{
                    position: absolute;
                    top: 4.85rem;;
                    left: 0;
                    right: 0;
                    p{
                        text-indent: 1em;
                    }
                }
            }
            .title___{
                text-align: center;
                font-size: .28rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #525252;
                margin-bottom: .4rem;
            }
            h3{
                margin-top: .3rem;
                text-align: center;
                color: #FFF0D3;
                font-size: .32rem;
            }
            .title_p__{
                margin-top: .2rem;
                margin-bottom: .8rem;
                text-align: center;
                font-size:.28rem ;
                color: #FCAF93;
            }
           
        }
        .bottom_title{
            font-size: .4rem;
            font-weight: 400;
            color: #FFF0D3;
            text-align: center;
        }
    }
    footer{
        padding-bottom: 1.2rem;
        .top_brand{
            text-align: center;
            .img_box{
                display: inline-block;
                margin-right: .2rem;
            }
            .img_box:last-child{
                margin-right: 0;
            }
        }
        .buttom_anchor{
            position: fixed;
            bottom: 0;
            width: 7.5rem;
            text-align: center;
            line-height: 1.11rem;
            font-size: .36rem;
            background: #E7010E;
            color: #FEFBFB;
            height: 1.11rem;
            animation-name: myfirst;
            animation-duration: 1s;
            animation-iteration-count:infinite;
            -webkit-animation-iteration-count:infinite; /* Safari 和 Chrome */
        }
        @keyframes myfirst
         {
             0%   {transform:scale(1);}
             50%  {transform:scale(1.1)}
             100% {transform:scale(1)}
         }
    }
    .wrapper{
       width: 7.5rem;
       height: 100%;
       margin: 0 auto;
        .block {
            position: fixed;
            top:50%;
            left: 50%;
            margin: 0 auto;
            width: 5.5rem;
            height: 9.3rem;
            transform: translate(-50%,-50%);
            background-color: white;
            border-radius: .4rem;
            padding: 0.4rem 0;
            .title{
                color: #CF031A;
                text-align: center;
                font-size: .352rem;
                font-weight: bold;
                margin: 0;
                margin-top: .1rem;
            }
            .title_tow{
                margin-top: .2rem;
            }
            .bg_box_chunk_{
                overflow: hidden;
                margin-top: .9rem;
                .img_bg_box{
                    width: 4.48rem ;
                    height: .5rem ;
                    background:url('https://cdn.zhiyileiju.cn/WechatIMG599.pic') no-repeat ;
                    background-size: 100% 100%;
                    margin: 0 auto;
                    margin-top: 0.13rem;
                }
                .only_remain_text{
                    float: right;
                    font-size: .26rem;
                    color: #F5A625;
                    margin-right: .21rem;
                }
                .bottom_text_box__{
                    // position: relative;
                    overflow: hidden;
                    width: 5.5rem;
                    white-space : nowrap;
                    .bottom_text__{
                        // position: absolute;
                        float: left;
                        color: #525252;
                        font-size: .26rem;
                        margin-top: .29rem;
                    }
                    .bottom_text__:nth-child(1){
                        margin-left: .26rem;
                    }
                    .bottom_text__:nth-child(2){
                        margin-left: 1.02rem;
                    }
                   
                    .bottom_text__:nth-child(3){
                        margin-left: .4rem;
                    }
                    @media screen and (max-width: 340px) {
                        .bottom_text__:nth-child(3){
                            margin-left: .2rem;
                        }
                    }
                }
            }
            .payed{
                width: 4.5rem;
                height: .98rem;
                font-size: .38rem;
                border-radius: 49px;
                text-align: center;
                margin: 0 auto;
                line-height: .98rem;
            }
            .payment_{
                margin-top: 1.09rem;
                color: #fff;
                background: #CF031A;
            }
            .unpay_{
                margin-top: .31rem;
                color: #fff;
                background: #F5A625;
            }
            .Dcheckorder_{
                margin-top: .31rem;
                box-sizing: border-box;
                border: 1px solid #F5A625;
            }
            .count_down_box_{
                text-align: center;
                margin-top: .4rem;
                .colon {
                    display: inline-block;
                    margin: 0 .04rem;
                    font-size: .2rem;
                    color: #b63437;
                }
                .block_ {
                    display: inline-block;
                    width: .43rem;
                    color: #fff;
                    font-size: .24rem;
                    text-align: center;
                    background-color: #b63437;
                    box-sizing:border-box;
                    border: 1px solid #fa455f;
                }
                .text{
                    margin-left: .1rem;
                    font-size: .14rem;
                    color: #525252;
                }   
            }
        }
    }
     //投诉
        .fixation_complaint{
            position: fixed;
            width: .48rem;
            top: 3.02rem;
            right: 0;
            z-index: 10;
        }
}
</style>
<style lang="scss">
    .home_marriage_test_payment{
        .van-popup{
            background: none;
        }
    }
</style>
